<template>
    <div class="modal add_question" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Client Tracking <span>Add Question</span></h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" ref="progress-custom-question-form" class="progress-custom-question-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Question Type</label>
                                <div class="field_wpr m-0">
                                    <multiselect
                                        v-model="form.type"
                                        v-bind="questionTypes"
                                        placeholder="Select type.."
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Question Text</label>
                                <div class="" :class="{ 'has-error': errors.title }">
                                    <Field autocomplete="off" name="title" v-model="form.title" :class="{ 'has-error': errors.title }" rules="required">
                                        <redactor v-model="form.title" name="title" :default-value="question.title" :reset-watcher="resetWatcher" />
                                    </Field>
                                </div>
                                <ErrorMessage name="title" class="text-danger" />
                            </div>
                        </div>
                        <div v-if="form.type == 'assessment'">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Question on goal setting form</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.goal_title }">
                                        <Field autocomplete="off" type="textarea" name="goal_title" v-model="form.goal_title" rules="required" label="goal title">
                                            <textarea cols="10" rows="10" placeholder="Thank you Message Goes Here..." v-model="form.goal_title"></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="goal_title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Portal Label</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.short_title }">
                                        <Field autocomplete="off" type="text" name="short_title" rules="required" placeholder="Assessment title on report.." v-model="form.short_title" label="portal title" />
                                    </div>
                                    <ErrorMessage name="short_title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Assessment Unit</label>
                                    <div class="field_wpr m-0">
                                        <multiselect
                                            v-model="form.unit"
                                            :groups="true"
                                            :searchable="true"
                                            :options="assessmentUnits"
                                            valueProp="value"
                                            label="value"
                                            placeholder="Select unit..."
                                            :group-select="false"
                                            group-label="type"
                                            group-options="units"
                                            :group-hide-empty="true"
                                            rules="required"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span class="status-tabs-header">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                 <span>{{ option.value }}</span>
                                           </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3" v-if="form.type == 'radio' || form.type == 'checkbox'">
                            <h3 class="sub_header">Answer/Choice</h3>
                            <ul class="mcq_list">
                                <li v-for="(option, o) of form.options" :key="o">
                                    <input type="text" :placeholder="`Choice #${o+1}`" v-model="form.options[o].title">
                                    <button type="button" class="danger pointer" @click="removeOption(o)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </li>
                            </ul>
                            <button type="button" class="add_btn ml-auto mt-2 mr-0 pointer" @click="addOption()">
                                <i class="fas fa-plus"></i> Add Choice
                            </button>
                        </div>
                        <label for="question_required" class="switch_option capsule_btn m-0">
                            <h5 class="large">Required?</h5>
                            <input type="checkbox" id="question_required" :true-value="1" :false-value="0" v-model="form.required" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="questionSaveLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="questionSaveLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="questionSaveLoader"></i> {{ questionSaveLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Progress Custom Question',

        data () {
            return {
                form: {
                    id: 0,
                    progress_tracking_id: 0,
                    type: 'assessment',
                    title: '',
                    goal_title: '',
                    unit: { value: 'Seconds' },
                    short_title: '',
                    required: 0,
                    options: [],
                    progress_question_section_id: this.sectionId,
                },
                questionTypes: {
                    mode: 'single',
                    value: ['radio'],
                    options: [
                        { value: 'radio', label: 'Multiple Choice (Only One Answer)' },
                        { value: 'checkbox', label: 'Multiple Choice (Multple Answers)' },
                        { value: 'textarea', label: 'Comment/Essay Box' },
                        { value: 'true-false', label: 'True/False' },
                        { value: 'text', label: 'Short Answer' },
                        { value: 'assessment', label: 'Assessment' },
                        { value: 'file', label: 'Upload' },
                    ],
                    searchable: true,
                },
                assessmentUnits: [
                      {
                          type: 'Time',
                          units: [
                              {
                                  value: 'Seconds',
                              },
                              {
                                  value: 'Minutes',
                              },
                              {
                                  value: 'Hours',
                              },
                              {
                                  value: 'Days',
                              },
                              {
                                  value: 'Months',
                              },
                              {
                                  value: 'Years',
                              },
                          ]
                      },
                      {
                          type: 'Distance',
                          units: [
                              {
                                  value: 'Millimeters',
                              },
                              {
                                  value: 'Centimeters',
                              },
                              {
                                  value: 'Inches',
                              },
                              {
                                  value: 'Feet',
                              },
                              {
                                  value: 'Yards',
                              },
                              {
                                  value: 'Meters',
                              },
                          ]
                      },
                      {
                          type: 'Liquid Measures',
                          units: [
                              {
                                  value: 'Milliliters',
                              },
                              {
                                  value: 'CCs',
                              },
                              {
                                  value: 'Ounces',
                              },
                              {
                                  value: 'Cups',
                              },
                              {
                                  value: 'Quarts',
                              },
                              {
                                  value: 'Pints',
                              },
                              {
                                  value: 'Liters',
                              },
                              {
                                  value: 'Gallons',
                              },
                          ]
                      },
                      {
                          type: 'Dry Measures',
                          units: [
                              {
                                  value: 'Pinch',
                              },
                              {
                                  value: 'Dash',
                              },
                              {
                                  value: 'Tsp',
                              },
                              {
                                  value: 'Tbs',
                              },
                              {
                                  value: 'Cups',
                              },
                              {
                                  value: 'Grams',
                              },
                              {
                                  value: 'Milligrams',
                              },
                              {
                                  value: 'Micrograms',
                              },
                          ]
                      },
                      {
                          type: 'Visual Measures',
                          units: [
                              {
                                  value: 'Fists',
                              },
                              {
                                  value: 'Palms',
                              },
                              {
                                  value: 'Thumbs',
                              },
                              {
                                  value: 'Pieces',
                              },
                              {
                                  value: 'Servings',
                              },
                          ]
                      },
                      {
                          type: 'Temperature',
                          units: [
                              {
                                  value: 'Degs F',
                              },
                              {
                                  value: 'Degs C',
                              },
                          ]
                      },
                      {
                          type: 'Vitals',
                          units: [
                              {
                                  value: 'BMI',
                              },
                              {
                                  value: 'BMR',
                              },
                              {
                                  value: 'RHR',
                              },
                              {
                                  value: 'AHR',
                              },
                              {
                                  value: 'MHR',
                              },
                              {
                                  value: 'O2 Sat',
                              },
                              {
                                  value: 'BPM',
                              },
                              {
                                  value: 'BP',
                              },
                              {
                                  value: 'Vo2max',
                              },
                              {
                                  value: 'ROM',
                              },
                          ]
                      },
                      {
                          type: 'Fitness',
                          units: [
                              {
                                  value: 'Steps',
                              },
                              {
                                  value: 'PR',
                              },
                              {
                                  value: '1RM',
                              },
                              {
                                  value: '3RM',
                              },
                              {
                                  value: '5RM',
                              },
                              {
                                  value: '10RM',
                              },
                              {
                                  value: 'Reps',
                              },
                              {
                                  value: 'Sets',
                              },
                              {
                                  value: 'Watts',
                              },
                              {
                                  value: 'Supersets',
                              },
                              {
                                  value: 'Circuits',
                              },
                              {
                                  value: 'Rounds',
                              },
                              {
                                  value: 'AMRAP',
                              },
                              {
                                  value: 'Calories',
                              },
                              {
                                  value: 'Pain Index',
                              },
                          ]
                      },
                      {
                          type: 'Weight',
                          units: [
                              {
                                  value: 'Kilograms',
                              },
                              {
                                  value: 'Pounds',
                              },
                          ]
                      },
                      {
                          type: 'Subjective Measures',
                          units: [
                              {
                                  value: 'Points',
                              },
                              {
                                  value: 'Rating',
                              },
                              {
                                  value: 'Stars',
                              },
                          ]
                      },
                ],
                resetWatcher: 0,
            };
        },

        props: {
            modelValue: Boolean,
            question: Object,
            sectionId: Number,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                }
            },
        },

        computed: mapState({
            questionSaveLoader: state => state.progressTrackingModule.questionSaveLoader,
            progressTracking: state => state.progressTrackingModule.selectedProgressTracking,
        }),

        methods: {
            ...mapActions({
                saveCustomQuestion: 'progressTrackingModule/saveCustomQuestion',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.question.id ? vm.question.id : 0,
                    progress_tracking_id: vm.question.progress_tracking_id ? vm.question.progress_tracking_id : vm.progressTracking.id,
                    type: vm.question.type ? vm.question.type : 'assessment',
                    title: vm.question.title ? vm.question.title : '',
                    goal_title: vm.question.goal_title ? vm.question.goal_title : '',
                    unit: vm.question.unit ? vm.question.unit : 'Seconds',
                    short_title: vm.question.short_title ? vm.question.short_title : '',
                    required: vm.question.required ? vm.question.required : 0,
                    options: vm.question.options ? JSON.parse(JSON.stringify(vm.question.options)) : [],
                    progress_question_section_id: vm.question.progress_question_section_id ? vm.question.progress_question_section_id : vm.sectionId,
                };

                setTimeout(function () {
                    vm.resetWatcher += 1;
                }, 100);
            },

            addOption () {
                const vm = this;

                vm.form.options.push({ option: '', question_id: vm.question.id });
            },

            removeOption (index) {
                const vm = this;

                vm.form.options.splice(index, 1);
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.setFieldError = setFieldError;

                vm.saveCustomQuestion(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                })
            },
        },
    }
</script>

<style scoped>
    :deep(.progress-custom-question-form) {
        height: calc(100vh - 180px);
    }

    :deep(.progress-custom-question-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding-right: 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 15px;
        font-size: 14px;
        line-height: 19px;
        width: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }
</style>
